import React, { Fragment } from 'react';
import { GatsbyImage, IGatsbyImageData, StaticImage } from 'gatsby-plugin-image';
import { Panels } from '../components/Panels';
import { graphql, Link } from 'gatsby';
import { Hero } from '../components/Hero';
import { Seo } from '../components/Seo';

const Resoources: Page = ({ data: { items } }) => {
    return (
        <Fragment>
            <Seo
                title="Resources | Clermont Kids Dentistry & Orthodontics, P.A."
                description={`Here at Clermont Kids Dentistry & Orthodontics we strive to meet all your pediatric dental needs, from your little infant to your young adult. Please to hesitate to call or ask us about any of our dental services.`}
            />
            <main>
                <Hero type="resources" title="Resources">
                    <StaticImage
                        alt="resources button"
                        className="hero-image"
                        src="../images/resources-button.jpg"
                    />
                </Hero>
                <Panels>
                    {items.edges.map(
                        (service: {
                            node: {
                                frontmatter: {
                                    title:
                                        | boolean
                                        | React.Key
                                        | React.ReactElement<
                                              any,
                                              string | React.JSXElementConstructor<any>
                                          >
                                        | React.ReactFragment
                                        | null
                                        | undefined;
                                    thumbnail: {
                                        name: string;
                                        childImageSharp: { gatsbyImageData: IGatsbyImageData };
                                    };
                                    slug: string;
                                };
                            };
                        }) => {
                            if (service.node.frontmatter.title === 'HIPPA Patient Privacy') {
                                return (
                                    <a
                                        href="https://www.clermontkidsdentistry.com/sites/clermontkidsdentistry.com/files/forms/hipaa_patient_privacy_practices.pdf"
                                        key={service.node.frontmatter.title}
                                        target="__blank"
                                        rel="noopener noreferrer"
                                    >
                                        <GatsbyImage
                                            alt={service.node.frontmatter.thumbnail.name}
                                            image={
                                                service.node.frontmatter.thumbnail.childImageSharp
                                                    .gatsbyImageData
                                            }
                                        />
                                        <p>{service.node.frontmatter.title}</p>
                                    </a>
                                );
                            } else {
                                return (
                                    <Link
                                        key={service.node.frontmatter.title}
                                        to={service.node.frontmatter.slug}
                                    >
                                        <GatsbyImage
                                            alt={service.node.frontmatter.thumbnail.name}
                                            image={
                                                service.node.frontmatter.thumbnail.childImageSharp
                                                    .gatsbyImageData
                                            }
                                        />
                                        <p>{service.node.frontmatter.title}</p>
                                    </Link>
                                );
                            }
                        }
                    )}
                </Panels>
            </main>
        </Fragment>
    );
};

export const query = graphql`
    query {
        items: allMdx(filter: { frontmatter: { type: { eq: "resources" } } }) {
            edges {
                node {
                    id
                    frontmatter {
                        type
                        images {
                            childImageSharp {
                                gatsbyImageData(quality: 100, placeholder: NONE)
                            }
                            name
                        }
                        title
                        slug
                        thumbnail {
                            childImageSharp {
                                gatsbyImageData(quality: 100, placeholder: NONE)
                            }
                            name
                        }
                    }
                }
            }
        }
    }
`;

export default Resoources;
